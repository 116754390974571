<template>
    

    <v-header />

    <lazy-v-toast-container />

    <slot />

    <lazy-v-footer />

    <lazy-v-email-popup />

    <lazy-v-elevate-preview-toggle />
</template>

<script lang="ts" setup>
const { t } = useI18n();
const config = useRuntimeConfig();

/* eslint-disable @typescript-eslint/naming-convention */
useJsonld({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: `${config.public.appUrl}`,
    logo: 'https://cmd-prod.s3-eu-west-1.amazonaws.com/logo-masku.svg',
});
/* eslint-enable @typescript-eslint/naming-convention */

const siteTitle = t('masku-url');

useSeoMeta({
    titleTemplate(title) {
        return title ? `${title} / ${siteTitle}` : siteTitle;
    },
});

useDefaultDynamicHead();
</script>
